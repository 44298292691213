import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import DatePicker from "react-datepicker"
import {format} from 'date-fns';

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {dateFrom: null, dateTo: null, email: null, id: null, reqDateFrom: null, reqDateTo: null};
    this._bind(
      'handleReportSend',
      'onSuccess'
    )
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint + `?partnerId=${this.state.id}${this.state.reqDateFrom ? '&dateFrom='+this.state.reqDateFrom : ''}${this.state.reqDateTo ? '&dateTo='+this.state.reqDateTo : ''}${this.state.email ? '&email='+this.state.email : ''}`, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      this.setState({ preloader: false })
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }
  onSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Raport cykliczny został wysłany" }}), true)
  }

  handleReportSend(data) {
    console.log(data)
    this.shortFetch('POST', 'report/cyclical', data, this.onSuccess)
  }
  
  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/reports/cr" : "/en/reports/cr")
    }
  }


  render () {
    const page = this.props.data.manage
    return (
      (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/reports/r' : '/en/reports/r' }>{ page.reportsName }</NavLink>
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <span className="sep">|</span> }
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <NavLink to={ this.props.lang === "pl" ? '/reports/i' : '/en/reports/i' }>{ page.invoicesName }</NavLink> }
            { this.props.user.userType === "SUPER" ? (<><span className="sep">|</span> <NavLink to={ this.props.lang === "pl" ? '/reports/cr' : '/en/reports/cr' }>{ page.cyclicalReportsName }</NavLink></>) : null}
          </div>
          <div className="table-outer cyclical-reports">
            <div className="top">
              <h3>{ page.cyclicalReportsName} </h3>
            </div>
            <form onSubmit={(e) => {
              e.preventDefault();
              this.handleReportSend({
                dateFrom: this.state.reqDateFrom, 
                dateTo: this.state.reqDateTo, 
                partnerId: this.state.id, 
                email: this.state.email
              });
            }}>
              <div className='form'>
                <div className='input'>
                  <label htmlFor='cr-id'>Id firmy: *</label> 
                  <input type='number' aria-label="Wpisz id firmy" title="Wpisz poprawne id firmy (cyfry)" name='cr-id' required pattern="^[0-9]*$" placeholder='Wpisz id firmy' onChange={(e)=> {
                    if (e.target.value.match(/^[0-9]*$/)) {
                      this.setState({disabled: false})
                      this.setState({id: e.target.value})
                    }
                  }}></input>
                </div>
                <div className='input'>
                  <label htmlFor='cr-email'>Adres e-mail na który wysłać raport (zostaw puste aby wysłać zgodnie z konfiguracja):</label> 
                  <input type='email' aria-label="Wpisz adres e-mail" placeholder='Wpisz adres e-mail' name='cr-email' pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={(e)=> {
                    if (e.target.value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
                      this.setState({email: e.target.value})
                    }
                  }}></input>
                </div>
                <div className='input'>
                  <label className='date' style={{marginBottom: '20px'}}>Zakres generowanego raportu (zostaw puste aby wysłać zgodnie z konfiguracja): </label>
                    <div>
                      <label className='date'>Od: </label>
                      <DatePicker
                        selected={this.state.dateFrom}
                        onChange={(date) => {
                          if (date) {
                            this.setState({reqDateFrom: format(date, 'yyyy-MM-dd')});
                            this.setState({dateFrom: date});
                          }
                        }}
                        aria-label="Wybierz datę" 
                        title="Wybierz datę"
                        locale="pl"
                        dateFormat="dd/MM/yyyy"
                        id="cyclicalReportsDPFrom"
                        placeholderText={'Wybierz datę'} 
                      />
                    </div>
                    <div>
                    <label className='date'>Do: </label>
                      <DatePicker
                        selected={this.state.dateTo}
                        onChange={(date) => {
                          if (date) {
                            this.setState({reqDateTo: format(date, 'yyyy-MM-dd')})
                            this.setState({dateTo: date});
                          }
                        }}
                        locale="pl"
                        aria-label="Wybierz datę" 
                        title="Wybierz datę"
                        dateFormat="dd/MM/yyyy"
                        id="cyclicalReportsDPTo"
                        placeholderText={'Wybierz datę'} 
                      />
                    </div>
                </div>
              </div>
              <div className="buttons">
              <button className="btn yellow small" id="button" type='submit'>
                <span>Wyślij raport</span>
              </button>
            </div>
            </form>
          </div>
        </div>
      )
    )
  }
}
